body {
  background-color: #345;
  overflow: hidden;
}

.hoverButton {
  box-shadow: 0px 0px 0px blue;
  transition: box-shadow 0.2s;
  cursor: pointer;
}

.hoverButton:hover {
  box-shadow: 0px 0px 20px blue;
}

.topBarButton {
  padding: 20px;
  border-left: 1px solid grey;
  border-right: 1px solid grey;
  user-select: none;
  cursor: pointer;
  background-color: #667;
  font-size: 150%;
  transition: background-color 0.2s;
}

.topBarButton:hover {
  background-color: #778;
}

.selectedTopBarButton {
  padding: 20px;
  border-left: 1px solid grey;
  border-right: 1px solid grey;
  user-select: none;
  background-color: #445;
  font-size: 150%;
  transition: background-color 0.2s;
}
